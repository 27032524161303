<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Par&aacute;metros</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Veh&iacute;culos</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <router-link to="/membresias" class="text-900 line-height-3"
        >Administrar Membresias</router-link
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Membresias
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
    </div>
    <div class="col-12 estilos-form mt-2">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <span><strong>PLACA:</strong></span>
          <InputText v-model="placa" @keyup.enter="cargarMembresias()" />
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>NOMBRE/DESCRIPCI&Oacute;N:</strong></span>
          <InputText
            v-model="nombre_membresia"
            @keyup.enter="cargarMembresias()"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>REGISTROS:</strong></span>
          <Dropdown
            v-model="cantidadSeleccionada"
            :options="cantidades"
            optionLabel="label"
            optionValue="value"
            @change="cargarMembresias()"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ESTADO:</strong></span>
          <Dropdown
            v-model="estadoSeleccionado"
            :options="estados"
            optionLabel="label"
            optionValue="value"
            @change="cargarMembresias()"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span>&nbsp;</span>
          <Button
            label="BUSCAR"
            icon="pi pi-search"
            class="p-button-info"
            @click="cargarMembresias()"
          />
        </div>
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="dtmembresias"
        :value="membresias"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Membresias"
        responsiveLayout="scroll"
        selectionMode="single click"
        stripedRows
        showGridlines
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>

        <Column field="placa" header="PLACA"> </Column>
        <Column field="nombre_producto" header="NOMBRE"> </Column>
        <Column
          field="fecha_inicio"
          header="FECHA INICIO"
          style="font-size: 13px"
        >
          <template #body="{ data }">
            {{ formatDate(data.fecha_inicio) }}
          </template>
        </Column>
        <Column field="fecha_fin" header="FECHA FIN" style="font-size: 13px">
          <template #body="{ data }">
            {{ formatDate(data.fecha_fin) }}
          </template>
        </Column>
        <Column
          field="cantidad"
          header="CANT. LAVADO"
          style="font-weight: bold; text-align: center"
        >
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]"
              >{{ data.estado == 1 ? "ACTIVO" : "INACTIVO" }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              label="Inactivar"
              :disabled="slotProps.data.estado == 0"
              icon="pi pi-times"
              class="p-button-danger p-button-rounded p-button-sm"
              @click="confirmarInactivar(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="inactivarMembresiaDialog"
      :style="{ width: '400px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i
          class="pi pi-exclamation-triangle mr-3"
          style="font-size: 2rem; color: #ffcc00"
        ></i>
        <span v-if="membresia" style="font-size: 1.2rem"
          >Estas Seguro que quieres Inactivar al membresia?<br /><b
            >{{ membresia.placa }} *** {{ membresia.nombre_producto }}</b
          ></span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-success"
          @click="inactivarMembresiaDialog = false"
        />
        <Button
          label="Si, Inactivar"
          icon="pi pi-check"
          class="p-button-danger"
          @click="deleteMembresia"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import MembresiaService from "@/service/MembresiaService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      membresias: null,
      cargando: true,
      membresiaDialog: false,
      inactivarMembresiaDialog: false,
      enviado: false,
      estados: [
        { label: "TODOS", value: 2 },
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
      cantidades: [
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "200", value: 200 },
        { label: "500", value: 500 },
        { label: "1000", value: 1000 },
      ],
      cantidadSeleccionada: 50,
      placa: null,
      nombre_membresia: null,
      estadoSeleccionado: 2,
    };
  },
  membresiaService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.membresiaService = new MembresiaService();
  },
  mounted() {
    this.cargarMembresias();
  },
  methods: {
    formatDate(fecha) {
      if (!fecha) return "";
      let fecha_array = fecha.split("-");
      return `${fecha_array[2]}/${fecha_array[1]}/${fecha_array[0]}`;
    },
    cargarMembresias() {
      let datos = {
        placa: this.placa,
        nombre_membresia: this.nombre_membresia,
        estado: this.estadoSeleccionado,
        cantidad: this.cantidadSeleccionada,
      };

      this.membresiaService.getMembresiasAll(datos).then((data) => {
        this.membresias = data.membresias || [];
        this.totalRegistros = data.membresias ? data.membresias.length : 0;
        this.cargando = false;
      });
    },
    ocultarDialog() {
      this.membresiaDialog = false;
      this.enviado = false;
    },
    confirmarInactivar(membresia) {
      this.membresia = { ...membresia };
      this.inactivarMembresiaDialog = true;
    },
    deleteMembresia() {
      this.membresiaService.deleteMembresia(this.membresia).then((data) => {
        if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: data.mensaje,
            life: 3000,
          });
          this.cargarMembresias();
          this.inactivarMembresiaDialog = false;
          this.membresia = {};
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.mensaje,
            life: 3000,
          });
          this.inactivarMembresiaDialog = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.estilos-form {
  background-color: #ececec;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
</style>
