import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class MembresiaService {
  async getMembresiasAll(datos) {
    return await fetchWrapper.post(`${ruta}/membresias/all`, datos);
  }

  async deleteMembresia(membresia) {
    const membresiaEliminada = await fetchWrapper.delete(
      `${ruta}/membresias/` + membresia.id
    );
    return membresiaEliminada;
  }

}
